export default {
  props: {
  },
  computed: {
    gridColumns() {
      let cols = [
        {
          fix: true,
          name: 'processName',
          field: 'processName',
          // 공정
          label: 'LBLPROCESS',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          fix: true,
          name: 'hazardDisasterTypeName',
          field: 'hazardDisasterTypeName',
          label: '재해유형',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          fix: true,
          name: 'riskHazardQuestionName',
          field: 'riskHazardQuestionName',
          // 유해위험요인<br/>(위험한 상황과 사건)
          label: 'LBL0001150',
          align: 'left',
          style: 'width:350px',
          sortable: false,
        },
        // {
        //   fix: true,
        //   name: 'riskHazardPicture',
        //   field: 'riskHazardPicture',
        //   // 사진
        //   label: 'LBL0001175',
        //   align: 'center',
        //   style: 'width:40px',
        //   sortable: false,
        //   type: 'custom',
        // },
        {
          name: 'currentSafetyMeasures',
          field: 'currentSafetyMeasures',
          // 현재안전조치
          label: 'LBL0001025',
          align: 'left',
          style: 'width:180px',
          sortable: false,
        },
        {
          name: 'ramRiskLevelName',
          field: 'ramRiskLevelName',
          label: '개선 전<br/>판단결과',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          type: 'custom'
        },
        // {
        //   name: 'picture',
        //   field: 'picture',
        //   // 개선<br/>전/후<br/>사진
        //   label: 'LBL0001176',
        //   align: 'center',
        //   type: 'custom',
        //   style: 'width:70px',
        //   sortable: false,
        // },
        {
          name: 'improvementMeasures',
          field: 'improvementMeasures',
          // 개선대책
          label: '개선대책',
          align: 'left',
          style: 'width:180px',
          sortable: false,
        },
        {
          name: 'ramAfterRiskLevelName',
          field: 'ramAfterRiskLevelName',
          label: '개선 후<br/>판단결과',
          align: 'center',
          style: 'width:100px',
          sortable: false,
          type: 'custom'
        },
        {
          name: 'customCol',
          field: 'customCol',
          label: 'LBLIMPROVE',
          align: 'center',
          style: 'width:150px',
          type: 'custom',
          sortable: false
        },
      ];
      return cols;
    },
    gridMerge() {
      let merge = [
        { index: 0, colName: 'processName' },
      ];
      return merge;
    },
    imprProps() {
      let props = {
        tableKey: 'ramThirdAssessScenarioId', ibmTaskTypeCd: 'ITT0000013', ibmTaskUnderTypeCd: 'ITTU000015',
        requestContentsCols: ['riskHazardQuestionName']
      }
      return props;
    },
  },
  data() {
    return {
    };
  },
  methods: {
  },
}
